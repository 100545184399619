import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

import Map from './Map'

const MapWrapper = styled.div`
  height: 40vh !important;
  width: 100% !important;
  margin-bottom: 4rem;
`

const Footer = props => (
  <footer id="footer">
    <MapWrapper>
      <Map></Map>
    </MapWrapper>
    <section className="openhours">
      <h2>Öffnungszeiten</h2>
      <span>
        <strong>Montag, Dienstag, Donnerstag</strong>
      </span>
      <br></br>
      <span>8 - 18 Uhr</span>
      <br></br>
      <span>
        <strong>Mittwoch</strong>
      </span>
      <br></br>
      <span>8 - 19 Uhr</span>
      <br></br>
      <span>
        <strong>Freitag</strong>
      </span>
      <br></br>
      <span>8 - 13 Uhr</span>
    </section>
    <section>
      <h2>So erreichen Sie uns</h2>
      <dl className="alt">
        <dt>Addresse</dt>
        <dd>
          Zeil 81 (Eingang Holzgraben)
          <br></br> Frankfurt am Main
        </dd>
        <dt>Tel.</dt>
        <dd>069 28 76 50</dd>
        <dt>Email</dt>
        <dd>
          <a href="#">info@zahnarztpraxis-barashka.de</a>
        </dd>
        <Link to="impressum">Impressum</Link>
        <br></br>
        <Link to="datenschutz">Datenschutzerklärung</Link>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://www.facebook.com/drbarashka"
            className="icon fa-facebook alt"
            target="_blank"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.google.com/maps/place/Zahnarztpraxis+Dr.+med.+dent.+Ivanka+Barashka+%26+Krassimira+Baraschka/@50.1142677,8.6830915,20.38z/data=!4m5!3m4!1s0x47bd0dfd22003847:0x5b843832f7ac7d52!8m2!3d50.1142682!4d8.6831836"
            className="icon fa fa-map-pin alt"
            target="_blank"
          >
            <span className="label">Map</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy; 2022, Zahnarztpraxis Dr. med. dent. Ivanka Barashka & Krassimira
      Baraschka
    </p>
  </footer>
)

export default Footer
