import GoogleMapReact from 'google-map-react'
import React from 'react'

import Logo from '../assets/images/logo.inline.svg'

const Pin = () => (
  <Logo
    alt="Barashka logo"
    style={{
      width: '40px',
      height: '40px',
      position: 'relative',
      float: 'left',
    }}
  ></Logo>
)

class Map extends React.Component {
  static defaultProps = {
    /*key: 'AIzaSyBlvl1450nhgvJfUqegXbLuE95oD7FWWZ8',*/
    /*key: 'AIzaSyD6xsLJta8VRJvdsS9ZnH3NvDlq0fgLiGA',*/
    key: 'AIzaSyCiiP6WDWbnbd4UdC7lwpmENQLQdkBVNgM',
    center: { lat: 50.1142677, lng: 8.6830915 },
    zoom: 16,
    language: 'de',
  }

  render() {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: this.props.key }}
        language={this.props.language}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        defaultOptions={{ styles: this.props.styles }}
        options={mapOptions}
      >
        <Pin lat={50.1148} lng={8.6825} />
      </GoogleMapReact>
    )
  }
}

const mapOptions = {
  disableDefaultUI: true,
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'transit.station.bus',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit.station.rail',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ],
}

export default Map
